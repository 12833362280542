.twelth_fold_wrapper {
  // padding: 75px 99px;
  margin-top: 150px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content_wrapper {
    width: 88%;
    height: 100%;
    .question_title {
      text-align: center;
      font-size: var(--fs-Heading2XLarge);
      padding-bottom: 20px;
    }
    .tab_section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 30px;
      button {
        background: var(--clr-bg-neutral-800);
        border: none;
        padding: 15px 30px;
        border-radius: var(--radius-ButtonSize);
        font-size: var(--fs-BodyLarge);
        cursor: pointer;
        border:1px solid #bfb7b7;
        transition: all 0.3s ease-in-out;
        &:hover{
          background: var(--clr-Sal-Blue-400);
          color: var(--clr-white);
        }
        &.active {
          background: var(--clr-Sal-Blue-400);
          color: var(--clr-white);
          border:1px solid var(--clr-Sal-Blue-400) ;
          &:hover{
            background: var(--clr-black);
          }
        }
      }
    }
    .frequently_asked {
      background: var(--clr-neutral-a04);
      border-radius: 20px;
      padding: 40px;
      overflow: hidden;

      .questions_answers {
        width: 100%;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        .box {
          box-shadow: 0px 5px 16px 0px #080f340f;
          background: var(--clr-white);
          padding: 40px 32px;
          border-radius: 16px;
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          &:nth-child(2) {
            margin-right: 0;
          }
          &:nth-child(4) {
            margin-right: 0;
          }
          &:nth-child(6) {
            margin-right: 0;
          }
          .heading_wrapper {
            width: 90%;
            .question {
              font-size: var(--fs-Body2XLarge);
              font-weight: var(--fw-Medium);
            }
          }
          .answer {
            font-size: var(--fs-BodyLarge);
            font-weight: 400;
            margin-top: 24px;
          }
          .icon {
            display: flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            border-radius: 8px;

            &.plus_icon {
              background: var(--clr-neutral-a10);
              img {
                width: 18px;
              }
            }
            &.minus_icon {
              background: var(--clr-Sal-Blue-400);
              img {
                width: 18px;
              }
            }
          }
        }
      }
      .know_more {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        .know_btn {
          background: transparent;
          border: 1px solid var(--clr-black);
          padding: 15px 30px;
          border-radius: 4px;
          font-size: var(--fs-BodyLarge);
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .twelth_fold_wrapper .content_wrapper .frequently_asked .questions_answers {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .twelth_fold_wrapper
    .content_wrapper
    .frequently_asked
    .questions_answers
    .box {
    padding: 20px;
    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
