.Seventh_fold_inner{
    width: 100%;
    padding:0 85px 0;
    .fold_content{
        width: 100%;
        .heading{
            font-size: var(--fs-Heading2XLarge);
            font-weight: var(--fw-Regular);
            text-align: center;
        }
        .services{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap:64px;
            margin-top: 62px;
            .service_box{
                width:100%;
                .service_image{
                    aspect-ratio: 1;
                    border-radius: 10px;
                    img{
                        width: 100%;
                        border-radius: 10px;
                    }
                }
                .service_content{
                    .service_heading{
                        font-size: var(--fs-Heading2XMedium);
                        font-weight: var(--fw-Regular);
                        margin:26px 0 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:992px){
    .Seventh_fold_inner{
        padding:20px;
        .fold_content{
            .services{
                grid-template-columns: repeat(2,1fr);
                
            }
        }
    }
}

@media screen and (max-width:600px){
    .Seventh_fold_inner{
        padding:20px;
        .fold_content{
            .services{
                grid-template-columns: auto;
                
            }
        }
    }
}