.second_fold_wrapper {
  // padding: 75px 60px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 150px;
  .fold_wrapper {
    width: 90%;
    height: 100%;
    .title {
      font-size: var(--fs-Heading2XLarge);
      font-weight: var(--fw-Regular);
      line-height: 1.5;
      text-align: center;
      margin-bottom: 30px;
    }

    .wrapper {
      background: var(--clr-white);
      box-shadow: 0px 0px 32.6px -11px #215be826;
      padding: 30px;
      border-radius: 13px;

      .tab_section {
        display: flex;
        justify-content: center;
        gap: 20px;
        border-bottom: 1px solid var(--clr-neutral-a11);
        padding-bottom: 28px;

        &.bottom_section {
          border-bottom: none;

          button {
            background: var(--clr-bg-neutral-800);
            padding: 15px 30px;
            font-size: var(--fs-BodyLarge);
            border-radius: var(--radius-ButtonSize);
            cursor: pointer;
            border: 1px solid #bfb7b7;
            transition: all 0.3s ease-in-out;
            &:hover{
              background: var(--clr-Sal-Blue-400);
              color:var(--clr-white);
              border:1px solid var(--clr-Sal-Blue-400);
            }
            

            &.active{
              background: var(--clr-Sal-Blue-400);
              font-weight: var(--fw-SemiBold);
              color: var(--clr-white);
              border:none;
              &:hover{
                background: var(--clr-black);
                color:var(--clr-white)
              }
             
            }
          }
        }

        button {
          background: var(--clr-bg-neutral-800);
            padding: 15px 30px;
            font-size: var(--fs-BodyLarge);
            border-radius: var(--radius-ButtonSize);
            cursor: pointer;
            border: 1px solid #bfb7b7;
            transition: all 0.3s ease-in-out;
            &:hover{
              background: var(--clr-Sal-Blue-400);
              color:var(--clr-white);
              border-color:var(--clr-Sal-Blue-400);
            }
          a {
            text-decoration: none;
            color: var(--clr-black);
          }

          &.active {
            background: var(--clr-bg-neutral-600);
            font-weight: var(--fw-SemiBold);
            border-color:var(--clr-bg-neutral-600);
            &:hover{
              background: var(--clr-Sal-Blue-400);
            }
          }
        }
      }

      .content {
        .experience {
          padding: 36px 0 10px;
          text-align: center;
          font-size: var(--fs-Heading2XMedium);
          font-weight: var(--fw-SemiBold);
        }

        .experience_text {
          text-align: center;
          font-size: var(--fs-BodyLarge);
          font-weight: var(--fw-Regular);
          .highlight {
            color: var(--clr-Sal-Blue-400);
            font-weight: var(--fw-SemiBold);
          }
          .comprehensive_highlight {
            font-weight: var(--fw-SemiBold);
          }
        }

        .features {
          display: flex;
          //   grid-template-columns: repeat(4, 1fr);
          align-items: center;
          gap: 20px;
          margin: 50px 0 40px;

          &.comprehensive {
            flex-wrap: wrap;
            justify-content: center;
          }

          .feature_box {
            background: var(--clr-Sal-Blue-300);
            padding: 23px 13px;
            text-align: center;

            &.comprehensive_box {
              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              white-space: nowrap;
              padding: 10px 20px;
              gap: 10px;
              border-radius: 4px;

              .box_title {
                margin-top: 0;
                font-size: var(--fs-BodyMedium);
                font-weight: var(--fw-Medium);
              }
            }

            .box_title {
              font-size: var(--fs-BodyLarge);
              font-weight: var(--fw-SemiBold);
              margin-top: 15px;
              line-height: 1.5;
            }

            .box_content {
              font-size: var(--fs-BodyMedium);
              font-weight: var(--fw-Regular);
              line-height: 1.5;
            }
          }
        }
        .btn_section {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            border: none;
            background: var(--clr-Sal-Blue-400);
            padding: 15px 30px;
            font-size: var(--fs-BodyLarge);
            border-radius: var(--radius-ButtonSize);
            color: var(--clr-white);
            cursor: pointer;
            transition: all 0.3s; 
            &:hover{
              background-color:var(--clr-black);
            }
            
            a {
              text-decoration: none;
              color: var(--clr-white);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .second_fold_wrapper .fold_wrapper .wrapper .content .features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .second_fold_wrapper .fold_wrapper .wrapper {
    .content {
      .features {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }

      .btn_section {
        button {
          width: 100%;
          height: 50px;
          padding: 0;
        }
      }
    }
    .bottom_section {
      flex-direction: column;
    }
  }
}
