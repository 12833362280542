.contact_us_modal_wrapper {
  top: 20px;
}
.contact_us_modal_container {
  width: 100%;
  padding: 10px;
  top: 0px;
  height: 100%;
  display: flex;
  .left_wrapper {
    width: 40%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right_wrapper {
    width: 60%;
    display: flex;
    align-items: center;
    form {
      width: 100%;
      padding: 50px;
      //   height: max-content;
      height: 100%;
      position: relative;
      .content_wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        .form_control {
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: 60px;
          .error {
            color: red;
          }
          label {
            font-size: var(--fs-BodyMedium);
            font-weight: var(--fw-Medium);
          }
          input {
            padding: 10px;
            width: 100%;
            height: 100%;
            border: none;
            border-bottom: 1px solid var(--clr-Sal-Grey-300);
            font-size: var(--fs-BodySmall);
            font-weight: var(--fw-Medium);
            outline: none;
            font-family: var(--ff-body);
          }
          input::placeholder {
            font-family: var(--ff-body);
            font-size: var(--fs-BodySmall);
            font-weight: var(--fw-Medium);
          }
          .phone_number_wrapper {
            display: flex;
            .select_country_wrapper {
              width: 100px;
              height: 100%;
              border: none;
              .ant-select-selector {
                border: none;
              }
            }
          }
        }
      }
      .form_control_textarea {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 40px;
        height: 150px;
        .error {
          color: red;
        }
        textarea {
          border: none;
          border-bottom: 1px solid var(--clr-Sal-Grey-300);
          flex: 1;
          font-size: var(--fs-BodySmall);
          font-weight: var(--fw-Medium);
          outline: none;
          resize: none;
          padding: 10px;
          font-family: var(--ff-body);
        }
        textarea::placeholder {
          font-family: var(--ff-body);
          font-size: var(--fs-BodySmall);
          font-weight: var(--fw-Medium);
        }
        label {
          font-size: var(--fs-BodyMedium);
          font-weight: var(--fw-Medium);
        }
      }
      .action_btn_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 45px;
        z-index: 1;
        position: relative;
        button {
          width: 214px;
          height: 54px;
          border-radius: var(--radius-ButtonSize);
          background-color: var(--clr-Sal-Blue-400);
          color: var(--clr-white);
          border: none;
          font-size: var(--fs-BodySmall);
          font-weight: var(--fw-Medium);
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover{
            background-color:var(--clr-black);
          }
        }
      }
      .image_wrapper {
        position: absolute;
        bottom: 20px;
        right: 50px;
        width: 100%;
        height: 200px;
        // background: url("../../../assets/Calculator/SendImage.png");
        background-repeat: no-repeat;
        background-position: right;
      }
    }
  }
}
.ant-modal .ant-modal-content {
  padding: 0px;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .contact_us_modal_container {
    flex-direction: column;
    .left_wrapper {
      width: 100%;
    }
    .right_wrapper {
      order: -1;
      width: 100%;
      .image_wrapper {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .contact_us_modal_container .right_wrapper form {
    padding: 20px;
    .content_wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
    .action_btn_wrapper {
      button {
        width: 100%;
        height: 46px;
      }
    }
  }
}
