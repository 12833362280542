.footer_main_wrapper {
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--clr-Sal-Blue-100);

  // margin-top: 50px;
  .footer_content {
    width: 90%;
    height: 64%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer_upper_section {
      flex: 1;
      //   border: 1px solid black;
      display: flex;
      justify-content: space-between;
      gap: 80px;

      .first_wrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;
        .logo_wrapper {
          img {
            cursor: pointer;
          }
        }
        .description {
          font-size: var(--fs-BodyLarge);
          font-weight: var(--fw-Regular);
          line-height: 30px;
          color: var(--clr-neutral-a09);
        }

        .social_main_wrapper {
          display: flex;
          gap: 22px;
        }
      }

      .links_container {
        .heading {
          margin-bottom: 40px;
          h3 {
            font-size: var(--fs-BodyXLarge);
            font-weight: var(--fw-SemiBold);
          }
        }

        .links_wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;

          a {
            text-decoration: none;
            font-size: var(--fs-BodyLarge);
            font-weight: var(--fw-Regular);
            color: var(--clr-neutral-a09);
            text-wrap: nowrap;
          }
        }
      }

      .contact_us_wrapper {
        .heading {
          margin-bottom: 40px;

          h3 {
            font-size: var(--fs-BodyXLarge);
            font-weight: var(--fw-SemiBold);
          }
        }

        .info_container {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .info {
            display: flex;
            gap: 10px;

            span {
              text-wrap: wrap;
              color: var(--clr-neutral-a09);
              a {
                text-decoration: none;
                color: var(--clr-neutral-a09);
              }
            }
          }
        }
      }
    }

    .break {
      width: 100%;
      border: 1px solid var(--clr-neutral-a08);
    }

    .footer_lower_section {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;

      .left_wrapper {
        font-size: var(--fs-BodyLarge);
        font-weight: var(--fw-Regular);
        color: var(--clr-neutral-a09);
      }

      .right_wrapper {
        .text {
          font-size: var(--fs-BodyLarge);
          font-weight: var(--fw-Regular);
          color: var(--clr-Sal-Blue-320);
        }

        a {
          font-size: var(--fs-BodyLarge);
          font-weight: var(--fw-Regular);
          color: var(--clr-Sal-Blue-500);
          line-height: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .footer_main_wrapper .footer_content .footer_upper_section {
    gap: 30px;
  }
}

@media screen and (max-width: 1050px) {
  .footer_main_wrapper {
    height: max-content;

    .footer_content {
      height: 100%;
      width: 100%;

      .footer_upper_section {
        flex-direction: column;
        padding: 10rem 4rem 4rem 4rem;

        .first_wrapper {
          .description {
            display: none;
          }

          .social_main_wrapper {
            display: none;
          }
        }

        .links_container {
          .links_wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }

      .footer_lower_section {
        padding: 0px;

        .left_wrapper {
          width: 100%;
          text-align: center;
        }

        .right_wrapper {
          display: none;
        }
      }
    }
  }
}
.scroll_to_top{
    position: fixed;
    bottom: 30px;
    z-index: 99;
    background: var(--clr-Sal-Blue-400);
    width: 50px;
    height: 50px;
    color: var(--clr-white);
    right: 20px;
    border-radius: 6px;
    cursor: pointer;
    padding:10px;
    &:hover{
      background: var(--clr-black);
    }
    svg{
      fill: var(--clr-white);
      
      
    }
}
