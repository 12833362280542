.seventh_fold_wrapper {
  // padding: 75px 151px;
  //margin: 100px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  //   border: 1px solid black;
  .editing_workflow {
    width: 85%;
    height: 100%;

    .workFlow_heading {
      text-align: center;
      font-size: var(--fs-Heading2XLarge);
      margin-bottom: 40px;
    }

    .workflow_features {
      .workflow_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 93px;

        &:not(:last-child) {
          margin-bottom: 90px;
        }

        &.reverse {
          flex-direction: row-reverse;
        }

        .workflow_description {
          flex: 1;

          .title_heading {
            font-size: var(--fs-BodyLarge);
            font-weight: 700;
            margin-bottom: 17px;
          }

          .text_description {
            font-size: var(--fs-BodyLarge);
            font-weight: 400;

            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }

        .workflow_image {
          flex: 1;
          box-shadow: 7px 8px 11.6px 0px #00000017;
          border-radius: 8px;
          border: 1px solid var(--clr-neutral-a20);

          img {
            width: 100%;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .seventh_fold_wrapper .editing_workflow .workflow_features .workflow_box {
    flex-direction: column-reverse;

    &.reverse {
      flex-direction: column-reverse;
    }
  }

  .reverse {
    flex-direction: column-reverse;
  }
}