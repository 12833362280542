.sixth_fold_inner{
    width: 100%;
    padding:150px 60px 0;
    .slider_boxes{
        width: 100%;
        padding-bottom: 12%;
        .box{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            justify-content: center;
            gap:70px;
            &:not(:last-child){
                margin-bottom: 90px;
            }
            &.reverse{
                grid-template-columns: repeat(2, 1fr);
                .image_box{
                    order:2;
                }
                .content_box{
                    order:1;
                }
            }
            .image_box{
                aspect-ratio: 1.1;
                border:1px solid var(--clr-bg-neutral-700);
                border-radius: 10px;
                img{
                    border-radius: 10px;
                    width: 100%;
                }

            }
            .content_box{
                .title{
                    font-size: var(--fs-Heading3XLarge);
                    font-weight: var(--fw-Regular);
                    margin-bottom: 12px;
                }
                .description{
                    font-size: var(--fs-BodyLarge);
                    font-weight: var(--fw-Regular);
                    
                }
                button{
                    background: var(--clr-Sal-Blue-400);
                    border:none;
                    padding:14px 22px;
                    margin-top: 26px;
                    border-radius: 6px;
                    color:var(--clr-white);
                    font-size: var(--fs-BodyMedium);
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    &:hover{
                        background-color:var(--clr-black);
                    }
                }
            }
        }
    }
}

@media screen and (max-width:600px){
    .sixth_fold_inner{
        width:100%;
        padding:20px;
        .slider_boxes{
            .box{
                grid-template-columns: auto;
                
                &.reverse{
                    grid-template-columns: auto;
                    .image_box{
                        order:1;
                       
                    }
                    .content_box{
                        order:2;
                    }
                }
            }
        }
    }
}