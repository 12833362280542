.tenthFold_main_container {
  width: 100%;
  height: 100%;
  margin-bottom: 150px;
  .heading {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    h1 {
      width: max-content;
      text-align: center;
      font-size: var(--fs-Heading2XLarge);
      font-weight: var(--fw-Regular);
    }
  }
  .image-section {
    width: 100%;
    section {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      .corousel {
        display: flex;
        gap: 40px;
        .card_wrapper {
          width: 160px;
          height: 70px;
          border: 1px solid var(--clr-neutral-a07);
          border-radius: 6px;
          .image_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }
        }
        .card_wrapper:nth-last-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .tenthFold_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      width: 90%;
    }
    .image-section {
      width: 90%;
    }
  }
}
