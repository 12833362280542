.fifth_fold_wrapper {
  // padding:75px 60px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 150px;
  .manual_editing {
    width: 90%;
    height: 100%;
    .editing_title {
      font-size: var(--fs-Heading2XLarge);
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      padding-bottom: 10px;
    }
    .sub_title {
      font-size: var(--fs-BodyLarge);
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 45px;
    }
    .content_table {
      width: 100%;
      position: relative;
      border: 1px solid var(--clr-neutral-a01);
      border-radius: 12px;
      .listing_table {
        width: 100%;
        border-radius: 12px;
        tr {
          border-bottom: 1px solid var(--clr-neutral-a01);
          &:last-child {
            border-bottom: none;
          }
          th {
            font-size: var(--fs-BodyLarge);
            font-weight: 700;
            padding: 26px 0 26px 62px;
            &.thirdColumn {
              background-color: rgba(34, 150, 243, 0.05);
              border-radius: 12px 12px 0 0;
            }
          }
          td {
            font-size: var(--fs-BodyLarge);
            font-weight: 400;
            padding: 15px 30px 20px 62px;
            &.firstColumn {
              font-weight: 600;
            }
            &.thirdColumn {
              background-color: rgba(34, 150, 243, 0.05);
            }
          }
        }
      }
      .show_button {
        position: absolute;
        bottom: -30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index:8;
        button {
          background: var(--clr-Sal-Blue-320);

          border: none;
          padding: 15px 30px;
          border-radius:var(--radius-ButtonSize);
          color: var(--clr-Sal-Blue-600);
          font-weight: 600;
          font-size: var(--fs-BodyLarge);
          cursor: pointer;
          transition:all 0.3s ease-in-out;
          &:hover{
            background: var(--clr-black);
            color:var(--clr-white);
          }
          
        }
      }
    }
    .mobile_content_table {
      display: none;
    }
  }
}
@media screen and (max-width: 992px) {
  .fifth_fold_wrapper .manual_editing {
    .content_table {
      display: none;
    }
    .mobile_content_table {
      display: block;
      .show_button {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          background: var(--clr-Sal-Blue-320);

          border: none;
          padding: 15px 30px;
          border-radius: var(--radius-ButtonSize);
          color: var(--clr-Sal-Blue-600);
          font-weight: 600;
          font-size: var(--fs-BodyLarge);
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover{
            background: var(--clr-black);
            color:var(--clr-white);
          }
        }
      }
      .content_card {
        width: 100%;
        border: 1px solid var(--clr-neutral-a01);
        border-radius: 6px;
        margin-bottom: 10px;
        .heading {
          width: 100%;
          height: 50px;
          padding-left: 20px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid var(--clr-neutral-a01);
          gap: 10px;
          font-size: var(--fs-BodySmall);
          font-weight: var(--fw-SemiBold);
          span {
            font-weight: var(--fw-Regular);
          }
        }
        .card_content {
          display: flex;
        }
        .left_wrapper,
        .right_wrapper {
          flex: 1;
          padding: 16px 20px;
          .content_heading {
            font-size: var(--fs-BodySmall);
            font-weight: var(--fw-SemiBold);
          }
          .content {
            font-size: var(--fs-BodySmall);
            font-weight: var(--fw-Regular);
          }
        }
        .left_wrapper {
          border-right: 1px solid var(--clr-neutral-a01);
          background-color: #e821210d;
        }
        .right_wrapper {
          background-color: #215be80d;
        }
      }
    }
  }
}
