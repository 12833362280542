.header_container {
  width: 100%;
  padding: 30px 60px;
  position: relative;
  z-index: 9;
  .header_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo_wrapper {
      // width: 220px;
      // img {
      //   width: 100%;
      //   height: 100%;
      //   object-fit: contain;
      //   cursor: pointer;
      // }
    }
    .right_wrapper {
      display: flex;
      gap: 50px;
      align-items: center;
      a {
        text-decoration: none;
        color: var(--clr-black);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: var(--fs-BodyMedium);
        font-weight: var(--fw-Regular);
        .arrow_icon_wrapper {
          width: max-content;
          height: max-content;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .solution {
        color: var(--clr-black);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: var(--fs-BodyMedium);
        font-weight: var(--fw-Regular);
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        .arrow_icon_wrapper {
          display: inline-flex;
          transition: all 0.3s ease-in-out;
          &.up {
            transform: rotate(180deg);
          }
        }
      }
      .solution_menu {
        position: absolute;
        background: var(--clr-white);
        top: 80%;
        border-radius: 6px;
        padding: 10px;
        button {
          background: transparent;
          border: none;
          width: 100%;
          cursor: pointer;

          font-size: 14px;
          display: flex;
          align-items: flex-start;
          justify-content: unset;
          padding: 10px;
          border-bottom: 1px solid var(--clr-neutral-a11);
          &:hover {
            background: var(--clr-Sal-Blue-400);
            color: var(--clr-white);
            border-radius: 6px;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
      .pricing {
        color: var(--clr-black);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: var(--fs-BodyMedium);
        font-weight: var(--fw-Regular);
        background: transparent;
        border: none;
        cursor: pointer;
      }
      .scheduleCallBtn {
        width: 190px;
        height: 50px;
        background-color: transparent;
        border-radius: var(--radius-ButtonSize);
        border: 1px solid var(--clr-Sal-Blue-400);
        color: var(--clr-Sal-Blue-400);
        font-size: var(--fs-BodyLarge);
        font-weight: var(--fw-SemiBold);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover{
          background-color: var(--clr-Sal-Blue-400);
          color:var(--clr-white);
        }
      }
    }
    .menuIconWrapper {
      display: none;
    }
  }
}
@media screen and (max-width: 992px) {
  .header_container {
    padding: 25px;
    .header_content {
      .right_wrapper {
        display: none;
      }
      .menuIconWrapper {
        display: block;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%W;
          object-fit: contain;
        }
      }
    }
  }
  .mobileMenuContainer {
    position: absolute;
    top: 0;
    background-color: var(--clr-white);
    width: 100%;
    height: 100vh;
    padding: 25px;
    z-index: 10;
    .mobileMenu_header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // .logo_wrapper {
      //   width: 220px;
      //   height: 40px;
      //   img {
      //     width: 100%;
      //     height: 100%;
      //     object-fit: contain;
      //     cursor: pointer;
      //   }
      // }
      .closeIconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 14px;
        height: 14px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .links_wrapper {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: center;
      .solution {
        color: var(--clr-black);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: var(--fs-BodyMedium);
        font-weight: var(--fw-Regular);
        background: transparent;
        border: none;
        cursor: pointer;

        .solution_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          .arrow_icon_wrapper {
            display: inline-flex;
            transition: all 0.3s ease-in-out;
            &.up {
              transform: rotate(180deg);
            }
          }
        }

        position: relative;

        .solution_menu {
          position: relative;
          background: var(--clr-white);
          top: 10%;
          border-radius: 6px;
          padding: 10px;
          button {
            background: transparent;
            border: none;
            width: 100%;
            cursor: pointer;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0 10px;
            &:last-child {
              padding-bottom: 0;
              padding-top: 10px;
              border-bottom: none;
            }
          }
        }
      }
      .pricing {
        color: var(--clr-black);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: var(--fs-BodyMedium);
        font-weight: var(--fw-Regular);
        background: transparent;
        border: none;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: var(--clr-black);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: var(--fs-BodyMedium);
        font-weight: var(--fw-Regular);
        .arrow_icon_wrapper {
          width: max-content;
          height: max-content;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .break {
        width: 100%;
        border: 1px solid #d9d9d9;
      }
      .scheduleCallBtn {
        width: 190px;
        height: 50px;
        background-color: transparent;
        border-radius: var(--radius-ButtonSize);
        border: 1px solid var(--clr-Sal-Blue-400);
        color: var(--clr-Sal-Blue-400);
        font-size: var(--fs-BodyLarge);
        font-weight: var(--fw-SemiBold);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover{
          background-color: var(--clr-Sal-Blue-400);
          color:var(--clr-white);
        }
      }
    }
  }
}
