blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
abbr,
acronym {
  border: 0;
}
q:before,
q:after {
  content: "";
}

// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  box-sizing: border-box;
}

:root {
  --clr-white: #ffffff;
  --clr-black: #000000;
  // color-BLUE
  --clr-Sal-Blue-50: #e7ecff;
  --clr-Sal-Blue-100: #f3f7ff;
  --clr-Sal-Blue-200: #dae5ff;
  --clr-Sal-Blue-300: #f6f8ff;
  --clr-Sal-Blue-310: #ceddff;
  --clr-Sal-Blue-320: #cedcff;
  --clr-Sal-Blue-330: #6f6c90;
  --clr-Sal-Blue-400: #215be8;
  --clr-Sal-Blue-500: #2511a5;
  --clr-Sal-Blue-600: #00174d;
  --clr-Sal-Blue-700: #001a58;

  //COLOR-GREEN
  --clr-Sal-Green-200: #d6f5ce;

  //color yellow
  --clr-Sal-Yellow-200: #f4f5ce;
  --clr-Sal-Yellow-400: #f38a00;

  //color orange
  --clr-Sal-Orange-400: #fd4203;
  //color-grey
  --clr-Sal-Grey-300: #8d8d8d;
  --clr-Sal-Grey-400: #2c2c2c;
  --clr-neutral-a01: #e2e2e2;
  --clr-neutral-a02: #f7f7f7;
  --clr-neutral-a03: #f6f6f6;
  --clr-neutral-a04: #f9f9f9;
  --clr-neutral-a05: #f6f7f9;
  --clr-neutral-a06: #3b3b3b;
  --clr-neutral-a07: #dbdbdb;
  --clr-neutral-a08: #d9dbe9;
  --clr-neutral-a09: #6f6c90;
  --clr-neutral-a10: #f7f7ff;
  --clr-neutral-a11: #e4e4e4;
  --clr-neutral-a12: #e5e5e5;
  --clr-neutral-a13: #ecedf2;
  --clr-neutral-a14: #c1c1c1;
  --clr-neutral-a15: #f8e7cc;
  --clr-neutral-a16: #fbf4cd;
  --clr-neutral-a17: #e0e9f6;
  --clr-neutral-a18: #cde9d1;
  --clr-neutral-a19: #d2e9f6;
  --clr-neutral-a20: #e3e3e3;

  // bg-color
  --clr-bg-neutral-50: #e8edf8;
  --clr-bg-neutral-100: #f7fafe;
  --clr-bg-neutral-200: #f6f7fa;
  --clr-bg-neutral-300: #f0f0f0;
  --clr-bg-neutral-400: #e8e1c6;
  --clr-bg-neutral-500: #f4c9a7;
  --clr-bg-neutral-600: #dae5ff;
  --clr-bg-neutral-700: #dddddd;
  --clr-bg-neutral-800: #ececec;

  --radius-ButtonSize:6px;

  // font-fs
  --fs-DisplayXLarge: 72px;
  --fs-DisplayLarge: 62px;
  --fs-DisplayMedium: 52px;
  --fs-DisplaySmall: 48px;
  --fs-DisplaySmall2: 44px;
  --fs-Heading3XLarge: 42px;
  --fs-Heading2XLarge: 38px;
  --fs-HeadingXLarge: 34px;
  --fs-HeadingLarge: 30px;
  --fs-Heading2XMedium: 28px;
  --fs-HeadingMedium: 26px;
  --fs-HeadingSmall: 24px;
  --fs-Body2XLarge: 22px;
  --fs-BodyXLarge: 20px;
  --fs-BodyLarge: 18px;
  --fs-BodyMedium: 16px;
  --fs-BodySmall: 14px;
  --fs-CaptionMedium: 12px;
  --fs-CaptionSmall: 10px;

  // font-weight
  --fw-bolder: 800;
  --fw-Bold: 700;
  --fw-SemiBold: 600;
  --fw-Medium: 500;
  --fw-Regular: 400;
  --fw-light: 300;
  --fw-thin: 200;

  // font family
  --ff-body: Poppins;
  --tt-toggle: 0.3s;

  //linear_gradient
  --lg-content: linear-gradient(
    145deg,
    var(--clr-Pinkb35) 15%,
    var(--clr-yellowb35)
  );
}

// tab version
@media screen and (min-width: 601px) and (max-width: 992px) {
  :root {
    --clr-white: #ffffff;
    --clr-black: #000000;
    // color-BLUE
    --clr-Sal-Blue-50: #e7ecff;
    --clr-Sal-Blue-100: #f3f7ff;
    --clr-Sal-Blue-200: #dae5ff;
    --clr-Sal-Blue-300: #f6f8ff;
    --clr-Sal-Blue-310: #ceddff;
    --clr-Sal-Blue-320: #cedcff;
    --clr-Sal-Blue-330: #6f6c90;
    --clr-Sal-Blue-400: #215be8;
    --clr-Sal-Blue-500: #2511a5;
    --clr-Sal-Blue-600: #00174d;
    --clr-Sal-Blue-700: #001a58;

    //COLOR-GREEN
    --clr-Sal-Green-200: #d6f5ce;

    //color yellow
    --clr-Sal-Yellow-200: #f4f5ce;
    --clr-Sal-Yellow-400: #f38a00;

    --clr-neutral-a01: #e2e2e2;
    --clr-neutral-a02: #f7f7f7;
    --clr-neutral-a03: #f6f6f6;
    --clr-neutral-a04: #f9f9f9;
    --clr-neutral-a05: #f6f7f9;
    --clr-neutral-a06: #3b3b3b;
    --clr-neutral-a07: #dbdbdb;
    --clr-neutral-a08: #d9dbe9;
    

    // bg-color
    --clr-bg-neutral-50: #e8edf8;
    --clr-bg-neutral-100: #f7fafe;
    --clr-bg-neutral-200: #f6f7fa;
    --clr-bg-neutral-300: #f0f0f0;
    --clr-bg-neutral-400: #e8e1c6;
    --clr-bg-neutral-500: #f4c9a7;
    --clr-bg-neutral-600: #dae5ff;

    // font-fs
    --fs-DisplayXLarge: 72px;
    --fs-DisplayLarge: 62px;
    --fs-DisplayMedium: 52px;
    --fs-DisplaySmall: 48px;
    --fs-DisplaySmall2: 44px;
    --fs-Heading3XLarge: 42px;
    --fs-Heading2XLarge: 36px;
    --fs-HeadingXLarge: 34px;
    --fs-HeadingLarge: 30px;
    --fs-Heading2XMedium: 28px;
    --fs-HeadingMedium: 26px;
    --fs-HeadingSmall: 24px;
    --fs-Body2XLarge: 22px;
    --fs-BodyXLarge: 20px;
    --fs-BodyLarge: 18px;
    --fs-BodyMedium: 16px;
    --fs-BodySmall: 12px;
    --fs-CaptionMedium: 12px;
    --fs-CaptionSmall: 10px;

    // font-weight
    --fw-bolder: 800;
    --fw-Bold: 700;
    --fw-SemiBold: 600;
    --fw-Medium: 500;
    --fw-Regular: 400;
    --fw-light: 300;
    --fw-thin: 200;

    // font family
    --ff-body: Poppins;
    --tt-toggle: 0.3s;

    //linear_gradient
    --lg-content: linear-gradient(
      145deg,
      var(--clr-Pinkb35) 15%,
      var(--clr-yellowb35)
    );
  }
}

// mobile version
@media screen and (max-width: 600px) {
  :root {
    --clr-white: #ffffff;
    --clr-black: #000000;
    // color-BLUE
    --clr-Sal-Blue-100: #f3f7ff;
    --clr-Sal-Blue-200: #dae5ff;
    --clr-Sal-Blue-300: #f6f8ff;
    --clr-Sal-Blue-310: #ceddff;
    --clr-Sal-Blue-320: #cedcff;
    --clr-Sal-Blue-320: #6f6c90;
    --clr-Sal-Blue-400: #215be8;
    --clr-Sal-Blue-500: #2511a5;

    //COLOR-GREEN
    --clr-Sal-Green-200: #d6f5ce;

    //color yellow
    --clr-Sal-Yellow-200: #f4f5ce;
    --clr-Sal-Yellow-400: #f38a00;

    --clr-neutral-a01: #e2e2e2;
    --clr-neutral-a02: #f7f7f7;
    --clr-neutral-a03: #f6f6f6;
    --clr-neutral-a04: #f9f9f9;
    --clr-neutral-a05: #f6f7f9;
    --clr-neutral-a06: #3b3b3b;
    --clr-neutral-a07: #dbdbdb;
    --clr-neutral-a08: #d9dbe9;
    // bg-color
    --clr-bg-neutral-50: #e8edf8;
    --clr-bg-neutral-100: #f7fafe;
    --clr-bg-neutral-200: #f6f7fa;
    --clr-bg-neutral-300: #f0f0f0;
    --clr-bg-neutral-400: #e8e1c6;
    --clr-bg-neutral-500: #f4c9a7;
    --clr-bg-neutral-600: #dae5ff;

    // font-fs
    --fs-DisplayXLarge: 40px;
    --fs-DisplayLarge: 38px;
    --fs-DisplayMedium: 36px;
    --fs-DisplaySmall: 34px;
    --fs-DisplaySmall2: 30px;
    --fs-Heading3XLarge: 32px;
    --fs-Heading2XLarge: 28px;
    --fs-HeadingXLarge: 24px;
    --fs-HeadingLarge: 20px;
    --fs-Heading2XMedium: 20px;
    --fs-HeadingMedium: 18px;
    --fs-HeadingSmall: 16px;
    --fs-Body2XLarge: 18px;
    --fs-BodyXLarge: 16px;
    --fs-BodyLarge: 14px;
    --fs-BodyMedium: 12px;
    --fs-BodySmall: 10px;
    --fs-CaptionMedium: 12px;
    --fs-CaptionSmall: 10px;

    // font-weight
    --fw-bolder: 800;
    --fw-Bold: 700;
    --fw-SemiBold: 600;
    --fw-Medium: 500;
    --fw-Regular: 400;
    --fw-light: 300;
    --fw-thin: 200;

    // font family
    --ff-body: Poppins;
    --tt-toggle: 0.3s;

    //linear_gradient
    --lg-content: linear-gradient(
      145deg,
      var(--clr-Pinkb35) 15%,
      var(--clr-yellowb35)
    );
  }
}
