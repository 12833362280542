.eleventh_fold_wrapper {
  // padding:75px 154px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 1px solid black;
  .eleventh_wrapper {
    width: 85%;
    height: 100%;
    .testimonial_heading {
      text-align: center;
      font-size: var(--fs-Heading2XLarge);
      margin-bottom: 40px;
    }
    .section {
      // display: flex;
      // flex-wrap: wrap;
      // gap:37px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 35px;
      .section_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #f6f6f680;
        padding: 35px;
        border: 1px solid var(--clr-neutral-a12);
        border-radius: 6px;
        .description{
          .highlight_one{
            background: var(--clr-neutral-a15);
            font-weight: 500;
          }
          .highlight_two{
            background: var(--clr-neutral-a17);
            font-weight: 600;
          }
          .highlight_three{
            background: var(--clr-neutral-a16);
            font-weight: 500;
          }
          .highlight_four{
            background: var(--clr-neutral-a18);
            font-weight: 600;
          }
          .text_bold{
            font-weight: 600;
          }
          
        }
        .box_head {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 24px;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }
          .title {
            font-size: var(--fs-BodyMedium);

            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .eleventh_fold_wrapper .eleventh_wrapper .section {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .eleventh_fold_wrapper .eleventh_wrapper .section {
    grid-template-columns: repeat(1, 1fr);
  }
}
