.first_fold_main_container {
  width: 100%;
  height: 726px;
  background: url("../../../assets/Home/FirstFold/FirstFoldImg.webp") no-repeat
    center center;
  background-size: cover;
  position: relative;

  @media (max-width: 767px) {
    .container {
      width: 100%;
      padding: 0 20px;
    }
  }

  .first_fold_content_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 100px 0 0 100px;
    
    justify-content: space-between;

    .left_wrapper {
      width: 40%;
      height: 100%;
      //   border: 1px solid black;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: var(--fs-DisplaySmall2);
        font-weight: var(--fw-Regular);
        padding:66px 0 0;
        line-height: 1.5;

        span {
          font-weight: var(--fw-SemiBold);
          color: var(--clr-Sal-Orange-400);
        }
      }

      h2 {
        margin: 15px 0 0;
        font-size: var(--fs-BodyXLarge);
        font-weight: var(--fw-Regular);
      }
      .description {
        width: 100%;
        font-size: var(--fs-BodySmall);
        font-weight: var(--fw-Regular);
        padding: 67px 0px 52px 0px;
        span {
          font-weight: var(--fw-SemiBold);
        }
      }
    }

    .right_wrapper {
      width: 40%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      .slider {
        width: 100%;
        height: 95%;
        .image_box{
          width:60%;
          height: 100%;
          position: relative;
        }
        img {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: opacity 1s ease-in-out, transform 1s ease-in-out;
          background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          backdrop-filter: blur(33.02981948852539px);
          box-shadow: -3.93px -3.93px 196.61px 0px #FFFFFF05 inset, 14.94px 25.95px 27.52px 0px #0000000D;
          border-radius: 6px;
          &.active{
            opacity:1;
          }
          &.slide-3 {
            transform: scale(1); /* Slight zoom-out effect for cropping */
            -webkit-transform: scale(1);
          }
  
          /* When Slide 4 is active (cropped result), reset the transformation */
          &.active.slide-4 {
            transform: scale(1); /* Reset the zoom for the cropped image */
            -webkit-transform: scale(1);
          }
        }
        
      }
    }
  }
  .tryBtn_wrapper {
    margin-top: 36px;
    .tryBtn {
      width: 240px;
      height: 60px;
      border-radius: var(--radius-ButtonSize);
      background-color: var(--clr-black);
      border: none;
      color: var(--clr-white);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: var(--fs-Body2XLarge);
      font-weight: var(--fw-Medium);
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover{
        background-color: var(--clr-Sal-Blue-400);
        
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .first_fold_main_container
    .first_fold_content_wrapper
    .left_wrapper
    .description {
    margin: 40px 0px;
  }

  .first_fold_main_container .first_fold_content_wrapper .right_wrapper {
    width: 42%;
  }
  .first_fold_main_container
    .first_fold_content_wrapper
    .right_wrapper
    .slider {
    width: 100%;
    height: 95%;
    .image_box{
      width:100%;
      height: 100%;
      position: relative;
      
    }
  }
}
@media screen and (max-width: 1199px) {
  .first_fold_main_container {
    height: auto;

    .tryBtn {
      margin: 30px auto 0;
      width: 240px;
      height: 60px;
    }

    .first_fold_content_wrapper {
      flex-direction: column;
      padding: 100px 0 0 0;

      .left_wrapper {
        width: 100%;
        text-align: center;
        padding: 0 20px;
      }

      .right_wrapper {
        width: 50%;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 40px;
        height: 100vh;

        .slider {
          width: 100%;
          position: relative;
         
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .first_fold_main_container {
    height: auto;

    .tryBtn {
      margin: 30px auto 0;
      width: 240px;
      height: 60px;
    }

    .first_fold_content_wrapper {
      flex-direction: column;
      padding: 100px 0 0 0;

      .left_wrapper {
        width: 100%;
        text-align: center;
        padding: 0 20px;
      }

      .right_wrapper {
        width: 320px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 40px;
        height: 66vh;

        .slider {
          width: 100%;
          position: relative;
         
        }
      }
    }
  }
}
