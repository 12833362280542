.sixth_fold_wrapper {
  //   padding: 75px 146px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 0px;

  .sixth_fold_features {
    display: grid;
    gap: 40px;
    width: 85%;
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
    .features_inner {
      box-shadow: 0px 0px 23.9px 2px #215be814;
      // height: 279px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 80px 70px;
      border: 1px solid #ceddff9e;
      background: #f6f8ff9e;
      border-radius: 6px;
      .stats_number {
        font-size: var(--fs-DisplaySmall);
        color: var(--clr-Sal-Blue-500);
        font-weight: 700;
      }
      .stats_desc {
        font-size: var(--fs-BodyLarge);
        color: var(--clr-black);
        font-weight: 400;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .sixth_fold_wrapper .sixth_fold_features {
    grid-template-columns: repeat(1, 1fr);
    .features_inner {
      padding: 30px 70px;
    }
  }
}
