.fourth_fold_wrapper {
  // padding:75px 60px;
  // margin: 100px 0px;
  display: flex;
  justify-content: center;
  .realtime_list {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .list-title {
      font-size: var(--fs-Heading2XLarge);
      text-align: center;
    }
    .listing {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
      margin-top: 50px;
      .list_box {
        position: relative;
        border: 1px solid var(--clr-Sal-Blue-310);
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px;
        background: var(--clr-Sal-Blue-300);
        border-radius: 6px;
        .description {
          font-size: var(--fs-BodyLarge);
          font-weight: 400;
          color: var(--clr-black);
          line-height: 1.5;
        }

        .stepper {
          position: absolute;
          top: -20px;
          background: var(--clr-Sal-Blue-400);
          padding: 5px 20px;
          border-radius: 6px;
          .step_title {
            color: var(--clr-white);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .fourth_fold_wrapper .realtime_list .listing {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    .list_box {
      padding: 50px 60px;
    }
  }
}
@media screen and (max-width: 600px) {
  .fourth_fold_wrapper .realtime_list .listing {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
}
