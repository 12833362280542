.Eighth_fold_container {
  width: 100%;
  min-height: 600px;
  height: 90dvh;
  margin: 150px 0;
  position: relative;

  .eight_fold_content {
    width: 100%;
    height: 100%;
    padding: 0 170px;

    .heading {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;

      h1 {
        font-size: var(--fs-Heading2XLarge);
      }
    }

    .card_content_wrapper {
      width: 100%;
      height: calc(100% - 75px);
      background-color: var(--clr-Sal-Green-200);
      border-radius: 12px;
      position: relative;
      padding: 44px;

      .action_btn_wrapper {
        width: 100%;
        position: relative;
        bottom: -25px;
        text-align: center;

        button {
          width: 250px;
          height: 50px;
          border-radius: var(--radius-ButtonSize);
          background-color: var(--clr-Sal-Blue-400);
          border: none;
          color: var(--clr-white);
          font-size: var(--fs-BodyLarge);
          font-weight: var(--fw-Medium);
          cursor: pointer; // Add pointer cursor on hover
          transition: all 0.3s; 
          &:hover{
            background-color:var(--clr-black);
          }
        }
      }

      .card_content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;

        .first_row {
          display: flex;
          width: 100%;
          margin-bottom: 43px;
        }

        .second_row {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .zigzag-item:nth-child(1) {
            margin-left: 30px;
            margin-top: 20px;
          }

          .zigzag-item:nth-child(2) {
            margin-top: 20px;
          }

          .zigzag-item:nth-child(3) {
            margin-left: 116px;
          }
        }

        .third_row {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .zigzag-item:nth-child(1) {
            margin-left: 123px;
            margin-top: 69px;
          }

          .zigzag-item:nth-child(2) {
            margin-top: 48px;
          }
        }

        .fourth_row {
          display: flex;
          justify-content: center;
          width: 100%;

          .zigzag-item {
            margin-top: 26px;
          }
        }

        .fifth_row {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .zigzag-item:nth-child(2) {
            margin-right: 30px;
          }
        }

        .zigzag-item {
          width: auto;
          height: 50px;
          margin: 0;
          background-color: var(--clr-black);
          border: 1px solid #ddd;
          text-align: center;
          border-radius: 79px;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
          color: var(--clr-white);
          font-size: var(--fs-BodyMedium);
          animation: slideIn 2s ease-in-out infinite alternate-reverse; // Apply animation
          display: flex;
          align-items: center;
          // justify-content: center;

          .card_item {
            padding: 0 20px;
            display: flex;
            align-items: center;
            gap: 20px;
          }

          &.second {
            margin-left: 138px;
            margin-top: 30px;
            overflow: hidden;
          }

          &:nth-child(1) {
            animation-delay: 0s;
          }

          &:nth-child(2) {
            animation-delay: 0.2s;
          }

          &:nth-child(3) {
            animation-delay: 0.4s;
          }

          &:nth-child(4) {
            animation-delay: 0.5s;
          }

          &:nth-child(5) {
            animation-delay: 0.6s;
          }

          &:nth-child(6) {
            animation-delay: 0.7s;
          }

          &:nth-child(7) {
            animation-delay: 0.8s;
          }

          &:nth-child(8) {
            animation-delay: 0.9s;
          }

          &:nth-child(9) {
            animation-delay: 0.1s;
          }

          &:nth-child(10) {
            animation-delay: 0.5s;
          }
        }
      }

      // Keyframes for sliding in animation
      @keyframes slideIn {
        0% {
          transform: translateX(0%); // Start off-screen to the left
        }

        100% {
          transform: translateX(5%); // End at its original position
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .Eighth_fold_container {
    height: calc(100dvh + 80px);
    .eight_fold_content {
      padding: 0 30px;

      .card_content_wrapper {
        height: 100%;
        padding: 47px 30px;

        .card_content {
          .first_row {
            flex-direction: column;
            gap: 20px;
            justify-content: flex-end;
            margin-bottom: 0;

            .zigzag-item:nth-child(1) {
              margin-left: auto;
              white-space: nowrap;
            }

            .zigzag-item:nth-child(2) {
              margin-right: auto;
              margin-left: unset;
              margin-top: 0;
              white-space: nowrap;
            }
          }

          .second_row {
            flex-direction: column;

            .zigzag-item:nth-child(1) {
              margin-right: auto;
              margin-left: 0;
            }

            .zigzag-item:nth-child(3) {
              margin-right: auto;
              white-space: nowrap;
            }

            .zigzag-item {
              margin-left: auto;
              white-space: nowrap;
            }

            .zigzag-item:nth-child(2) {
              margin-left: auto;
              margin-bottom: 30px;
              white-space: nowrap;
            }
          }

          .third_row {
            flex-direction: column;

            .zigzag-item:nth-child(1) {
              margin-left: auto;
              margin-top: 30px;
              white-space: nowrap;
            }

            .zigzag-item:nth-child(2) {
              margin-right: auto;
              white-space: nowrap;
              margin-top: 20px;
            }
          }

          .fourth_row {
            flex-direction: column;

            .zigzag-item:nth-child(1),
            .zigzag-item:nth-child(2) {
              margin-right: auto;
              white-space: nowrap;
            }
          }

          .fifth_row {
            flex-direction: column;

            .zigzag-item:nth-child(1),
            .zigzag-item:nth-child(2) {
              margin-left: auto;
              margin-top: 30px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
