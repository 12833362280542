.contact_us_wrapper_container {
  width: 100%;
  height: 120dvh;
  background: url("../../assets/ContactUs/ContactUsImage.webp");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  background-size: cover;
  .content {
    width: 85%;
    height: 100%;
    top: 150px;
    position: relative;
    .heading_wrapper {
      width: 100%;
      text-align: center;
      h1 {
        font-size: var(--fs-DisplaySmall);
        font-weight: var(--fw-Regular);
      }
      h5 {
        font-size: var(--fs-BodyXLarge);
        font-weight: var(--fw-Regular);
      }
    }
    .contact_us {
      margin-top: 50px;
      background-color: var(--clr-white);
      border-radius: 10px;
    }
  }
}
@media screen and (max-width: 992px) {
  .contact_us_wrapper_container {
    height: 100%;

    .content {
      top: 100px;
    }
  }
}
