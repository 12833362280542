.calculator_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 180px 0px;
  .calculator_content {
    width: 85%;
    height: 100%;
    .heading {
      text-align: center;
      margin-bottom: 35px;
      h1 {
        font-size: var(--fs-Heading2XLarge);
      }
    }

    .calculator_container {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: var(--clr-bg-neutral-100);
      padding: 50px;
      .calculator_content {
        width: 100%;
        height: 100%;
        display: flex;
        .break {
          display: none;
        }
        .left_wrapper,
        .right_wrapper {
          flex: 1;
        }
        .left_wrapper {
          display: flex;
          flex-direction: column;
          .heading_wrapper {
            h2 {
              font-size: var(--fs-HeadingSmall);
              font-weight: var(--fw-SemiBold);
              line-height: 1.5;
              color: var(--clr-Sal-Grey-400);
            }
            margin-bottom: 10px;
          }
          .sub_heading {
            display: flex;
            gap: 15px;
            align-items: center;
            h4 {
              font-size: var(--fs-BodyLarge);
              font-weight: var(--fw-Medium);
              color: var(--clr-Sal-Grey-400);
            }
            .cost_wrapper {
              border: 1px solid var(--clr-Sal-Blue-400);
              color: var(--clr-Sal-Blue-400);
              width: 110px;
              height: 36px;
              font-size: var(--fs-BodySmall);
              font-weight: var(--fw-SemiBold);
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .images_wrapper {
            margin: 27px 0px;
            display: flex;
            width: 100%;
            img {
              width: 100%;
            }
            .before_image_wrapper,
            .after_image_wrapper {
              flex: 1;
              img {
                width: 100%;
                object-fit: contain;
              }
            }
          }
          .description_wrapper {
            p {
              font-size: var(--fs-BodyMedium);
              font-weight: var(--fw-Medium);
              color: var(--clr-Sal-Grey-400);
            }
          }
        }
        .right_wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          .right_wrapper_content {
            width: 100%;
            padding-left: 70px;
            height: max-content;
            form {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 30px;

              .form_control {
                display: flex;
                flex-direction: column;
                gap: 15px;
                .select_wrapper {
                  width: 100%;
                  height: 50px;
                  .ant-select-arrow {
                    color: var(--clr-black);
                  }
                }
                label {
                  font-size: var(--fs-BodyMedium);
                  font-weight: var(--fw-SemiBold);
                  color: var(--clr-Sal-Grey-400);
                }
                .radio_wrapper {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  gap: 16px;
                  .radioBtn {
                    width: 100%;
                    border-radius: 8px;
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                  }
                  .ant-radio-button-wrapper {
                    background-color: var(--clr-bg-neutral-50);
                    font-weight: var(--fw-Medium);
                    font-size: var(--fs-BodyMedium);
                    transition: all 0.3s ease-in-out;
                    &:hover{
                      background-color: var(--clr-black);
                      color:var(--clr-white);
                    }
                  }
                  .ant-radio-button-wrapper::before {
                    display: none;
                  }
                  .ant-radio-button-wrapper-checked {
                    background: var(--clr-Sal-Blue-400);
                    border-radius: var(--radius-ButtonSize);
                    color: var(--clr-white);
                    font-weight: var(--fw-SemiBold);
                    font-size: var(--fs-BodyMedium);
                    transition: all 0.3s ease-in-out;
                    &:hover{
                      background: var(--clr-black);
                    }
                  }
                }
                .input_wrapper {
                  height: 50px;
                  width: 100%;
                  display: flex;
                  border: 1px solid #0072dd2b;
                  border-radius: 8px;
                  justify-content: space-between;
                  padding: 14px;
                  gap: 10px;
                  background-color: var(--clr-white);
                  input {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    border: none;
                    font-size: var(--fs-BodyMedium);
                    font-weight: var(--fw-Regular);
                  }
                  input:focus {
                    border: none;
                    outline: none;
                  }
                  button,
                  .icon_wrapper {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid var(--clr-Sal-Blue-400);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    color: var(--clr-Sal-Blue-400);
                    padding: 0;
                    cursor: pointer;
                    font-size: var(--fs-BodySmall);
                    span {
                      width: max-content;
                      height: max-content;
                      line-height: 1;
                      display: inline-block;
                    }
                  }
                }
                .price_container {
                  label {
                    font-size: var(--fs-BodySmall);
                    font-weight: var(--fw-Regular);
                    color: var(--clr-Sal-Blue-700);
                  }
                  .price_wrapper {
                    margin-top: 10px;
                    font-size: var(--fs-HeadingLarge);
                    font-weight: var(--fw-SemiBold);
                    color: var(--clr-Sal-Blue-700);
                  }
                }
              }
              .action_btn_wrapper {
                width: 100%;
                button {
                  width: 100%;
                  height: 56px;
                  background-color: var(--clr-Sal-Blue-400);
                  border-radius: var(--radius-ButtonSize);
                  font-size: var(--fs-BodyLarge);
                  font-weight: var(--fw-SemiBold);
                  border: none;
                  color: var(--clr-white);
                  cursor: pointer;
                  transition: all 0.3s ease-in-out;
                  &:hover{
                    background-color: var(--clr-black);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .calculator_main_container .calculator_content .calculator_container {
    padding: 40px;
    .calculator_content {
      flex-direction: column;
      .break {
        display: block;
        border: 1px solid #0072dd2b;
        margin: 60px 0px;
      }
      .right_wrapper {
        .right_wrapper_content {
          padding: 0px;
          form {
            .form_control {
              .radio_wrapper {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
