.thirteen_fold_wrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .contact_us_form {
    width: 75%;
    height: 100%;
    background: var(--clr-white);
    border: 1px solid var(--clr-neutral-a12);
    border-radius: 12px;
    padding: 40px;
    margin-top: 150px;

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .field,
      .field_message {
        display: flex;
        flex-direction: column;

        // height: 70px;
        label {
          font-size: var(--fs-BodyMedium);
          margin-bottom: 4px;
        }

        .error {
          color: red;
          font-size: var(--fs-BodySmall);
        }

        .input_field {
          border: none;
          border-bottom: 1px solid var(--clr-neutral-a13);
          height: 40px;
          padding: 12px 0;
          color: var(--clr-black);
          font-family: var(--ff-Body);

          &:focus {
            outline: none;
          }

          &::placeholder {
            font-size: var(--fs-BodyMedium);
            color: var(--clr-neutral-a14);
            font-family: var(--ff-Body);
          }
        }

        .message {
          background: var(--clr-neutral-a05);
          border: none;
          flex: 1;
          padding: 20px 24px;
          color: var(--clr-black);
          border-radius: 4px;
          resize: none;
          font-family: var(--ff-Body);

          &::placeholder {
            font-size: var(--fs-BodyMedium);
            color: var(--clr-neutral-a14);
            font-family: var(--ff-Body);
          }

          &:focus {
            outline: none;
          }
        }
      }

      .field {
        height: 70px;
      }

      .field_message {
        flex: 1;
        gap: 10px;
      }

      .submitBtnWrapper {
        width: 100%;

        button {
          width: 230px;
          height: 44px;
          background-color: var(--clr-black);
          color: var(--clr-white);
          border-radius: var(--radius-ButtonSize);
          font-size: var(--fs-BodySmall);
          font-weight: var(--fw-SemiBold);
          font-family: var(--ff-Body);
          border: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: var(--clr-Sal-Blue-400);

          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .thirteen_fold_wrapper .contact_us_form {
    width: 90%;
    padding: 40px 30px;
  }
}