@keyframes slideAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
  height: 100%;
  width: 100%;
}

.looper__listInstance {
  display: flex;
  width: max-content;
  gap: 40px;
  animation: slideAnimation linear infinite;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnimation linear infinite;
}

.looper__innerList[data-animate="true"]:hover .looper__listInstance {
  cursor: pointer;
  animation-play-state: paused;
  transition: animation-play-state 0.3s ease;
}
.looper__listInstance {
  display: flex;
  width: max-content;
  animation: none;
}
