$poppins: "Poppins", sans-serif;

.first_fold_v1 {
  background: url("../../../../public/images/FirstFold/back2.jpg");
  width: 100%;
  height: 915px;
  background-size: cover;

  .logo {
    padding: 40px 40px 0;
  }

  .video_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    .title {
      font-size: 62px;
      line-height: 1.5;
      color: #3a3a91;
      font-weight: 700;
      font-family: $poppins;
    }

    .sub_text {
      font-size: 18px;
      line-height: 1.5;
      color: #3a3a91;
      font-family: $poppins;
      font-weight: 400;
      max-width: 85%;
      width: 100%;
      margin-top: 20px;
    }

    .button_section {
      //   background: #fff;
      //   padding: 20px 42px;
      //   border-radius: 16px;
      margin-top: 60px;

      button {
        background: #fff;
        border-radius: 16px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        color: #1b1a3d;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
        font-family: $poppins;
        font-weight: 500;
        border: none;
        cursor: pointer;
        width: 315px;
        height: 70px;
      }
      img {
        width: 40px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .first_fold_v1 {
    .video_section {
      .title {
        font-size: 42px;
      }
      .sub_text {
        max-width: 100%;
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .first_fold_v1 {
    height: 798px;
    .video_section {
      .title {
        font-size: 42px;
      }
      .sub_text {
        font-size: 16px;
        max-width: 100%;
        padding: 0 20px;
      }
      .button_section {
        button {
          width: 220px;
          height: 60px;
          font-size: 14px;
          img {
            width: 20px;
          }
        }
      }
    }
  }
}
