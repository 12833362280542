.first_fold_inner {
  background: url("../../../assets/FlowEdit/FirstFoldInner/bg.webp") center
    no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    height: 100%;
    width: 85%;
    text-align: center;
    padding: 180px 0 130px;
    .title {
      font-size: var(--fs-DisplaySmall2);
    }
    .subText {
      font-size: var(--fs-BodyXLarge);
    }
  }
}
@media screen and (max-width: 600px) {
  .first_fold_inner .content {
    gap: 30px;
  }
}
