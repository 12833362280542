.ninthFold_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  .ninthFold_content {
    width: 72%;
    height: 100%;
    .heading {
      text-align: center;
      margin-bottom: 35px;
      h1 {
        font-size: var(--fs-Heading2XLarge);
      }
    }
    .card_container {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 15px;
      .basic_card_wrapper {
        width: 45%;
        height: max-content;
        background-color: var(--clr-neutral-a03);
        border-radius: 5px;
        .card_content {
          padding: 30px 60px 70px 60px;
          display: flex;
          flex-direction: column;
          gap: 15px;
          .card_heading {
            font-size: var(--fs-BodyXLarge);
            font-weight: var(--fw-SemiBold);
          }
          ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            list-style: disc;
            padding-left: 20px;

            li {
              font-size: var(--fs-BodyMedium);
              font-weight: var(--fw-Regular);
            }
          }
          button {
            width: 145px;
            height: 50px;
            border-radius: 12px;
            color: var(--clr-white);
            background-color: var(--clr-Sal-Blue-400);
            border: none;
            cursor: pointer;
          }
          .description {
            width: 100%;
            font-size: var(--fs-BodyMedium);
            font-weight: var(--fw-Regular);
          }
        }
      }
      .professional_card_wrapper {
        width: 50%;
        background: #f38a000d;
        border-radius: 5px;
        .card_content {
          padding: 30px 60px 70px 60px;
          display: flex;
          flex-direction: column;
          gap: 15px;
          .card_heading {
            font-size: var(--fs-BodyXLarge);
            font-weight: var(--fw-SemiBold);
          }
          ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            list-style: disc;
            padding-left: 20px;
            li {
              font-size: var(--fs-BodyMedium);
              font-weight: var(--fw-Regular);
            }
            .bold {
              font-weight: var(--fw-SemiBold);
            }
          }
          button {
            width: 145px;
            height: 50px;
            border-radius: 12px;
            color: var(--clr-white);
            background-color: var(--clr-Sal-Blue-400);
            border: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .ninthFold_main_container {
    padding: 30px;
    .ninthFold_content {
      width: 100%;
      .card_container {
        flex-direction: column;
        .basic_card_wrapper {
          width: 100%;
        }
        .professional_card_wrapper {
          width: 100%;
        }
      }
    }
  }
}
