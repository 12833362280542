.twenty_fold_wrapper {
  // padding:75px 169px;
  margin: 150px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .fold_content {
    display: grid;
    gap: 40px;
    width: 85%;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
    .workflow_container {
      background: #f6f8ff9e;
      border-radius: 6px;
      border: 1px solid #ceddff9e;
      padding: 30px;
      .image_box {
        border-radius: 6px;
        text-align: center;
        aspect-ratio: 1.3;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .fold_title {
        font-size: var(--fs-BodyLarge);
        font-weight: 700;
        margin-bottom: 20px;
        span{
          font-weight: normal;
        }
      }
      .fold_description {
        font-size: var(--fs-BodyLarge);
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .twenty_fold_wrapper .fold_content {
    grid-template-columns: repeat(1, 1fr);
  }
}
