.third_fold_wrapper {
  margin: 150px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .flow_edit {
    width: 85%;
    height: 100%;
    .section_title {
      font-size: var(--fs-Heading2XLarge);
      line-height: 1.5;
      color: var(--clr-black);
      text-align: center;
      font-weight: 400;
      padding-bottom: 20px;
    }

    .features {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);
      .features_list {
        background: var(--clr-Sal-Blue-300);
        padding: 25px;
        max-width: 100%;
        border-radius: 6px;

        .list_image {
          width: 100%;
          margin-bottom: 10px;
          border-radius: 6px;
          box-shadow: 41px 30px 14px 0px #00000000;
          aspect-ratio: 1.49;
          img {
            width: 100%;
          }
        }

        .list_title {
          font-size: var(--fs-BodyLarge);
          font-weight: 600;
          line-height: 1.5;
          color: var(--clr-black);
          padding: 0 0 14px;
          // white-space: nowrap;
          .sub_title {
            font-size: var(--fs-BodyMedium);
            font-weight: normal;
          }
        }

        .inner_list {
          display: flex;
          flex-direction: column;

          .need {
            display: flex;
            gap: 20px;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .need_title {
              font-size: 14px;
              color: var(--clr-black);
              min-width: 68px;
              height: 28px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;

              &.primary {
                background: var(--clr-Sal-Green-200);
              }

              &.secondary {
                background: var(--clr-Sal-Yellow-200);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .third_fold_wrapper .flow_edit .features .features_list .list_image {
    aspect-ratio: 1.49;
  }
}

@media screen and (max-width: 1150px) {
  .third_fold_wrapper .flow_edit .features {
    grid-template-columns: repeat(1, 1fr);
  }
}
