.slider-container {
    position: relative;
    width: 100%;
    // max-width: 700px;
    height: 100%;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.before-image,
.after-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
}

.before-image img,
.after-image img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.before-image {
    z-index: 2;
    overflow: hidden;
}

.after-image {
    z-index: 1;
}

.slider-handle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #fff;
    cursor: ew-resize;
    z-index: 3;

    &::before {
        background: transparent;
        content: "";
        font-weight: 400;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        position: absolute;
        margin: 0 0 0 -14px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 3px solid white;
        top: 50%;
    }
}

//   .resize {
//     background-color: red; 
//   }

