$poppins: "Poppins", sans-serif;
.fourth_fold_v1 {
  // background: url('../../../assets/images/FourthFold/bg.svg') center no-repeat;
  background: url("../../../assets/Vpi/images/FourthFold/bg.svg") center
    no-repeat;
  background-size: cover;
  height: 430px;
  .fourth_wrapper {
    text-align: center;
    padding: 76px 0 0;
    .fourth_head {
      font-size: 50px;
      line-height: 1.5;
      font-weight: 600;
      color: #fff;
      font-family: $poppins;
      padding-bottom: 20px;
    }
    .fourth_text {
      font-size: 20px;
      color: #fff;
      font-family: $poppins;
      line-height: 1.5;
      padding-bottom: 60px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .fourth_fold_v1 {
    .container {
      max-width: 100%;
      padding: 0 20px;
      .fourth_wrapper {
        padding: 32px 0 0;
        .fourth_head {
          font-size: 30px;
        }
        .fourth_text {
          font-size: 16px;
          padding-bottom: 25px;
        }
        .image_block {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .fourth_fold_v1 {
    .container {
      max-width: 100%;
      padding: 0 20px;
      .fourth_wrapper {
        .image_block {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
  }
}
