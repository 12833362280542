.layout_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-image:url('../../assets/gradient-bg.svg'), url('../../assets/gradient-bg2.svg');
  background-repeat: no-repeat;
  background-position: left center, right bottom;
  background-attachment: fixed;
  .header_main_wrapper {
    width: 100%;
    position: absolute;
    top: 0;
  }
}
